import {Action, Actions, Container, GLIcon, ListStatesRadios, SecondaryTitle, WijmoGrid} from "@app/components";
import {useService} from "@app/hooks";
import {TermManagerLocale} from "@app/locales/localeid";
import {ITermManagerService} from "@app/service/term-manager/interface";
import {TermModel} from "@app/service/term-manager/model";
import {fmtMsg, GSAdminAction, ListStates, TYPES} from "@app/util";
import {Checkbox, Modal,} from "antd-min";
import {connect, GLGlobal, GLRouteComponentProps, LanguageDateFormat, MessageHelper, StateType} from "gl-commonui";
import moment from "moment";
import React, {FC, useEffect, useRef, useState} from "react";
import {withRouter} from "react-router";
import {TermEditor, TermEditorRefProps} from "./term-editor/term-editor";
import "./term-manager.less";

const { Grid, Column } = WijmoGrid;

interface TermManagerProps extends GLRouteComponentProps {}

interface TermCountInterface {
    allCnt: number;
    activeCnt: number;
    inActiveCnt: number;
}

type EditMode = undefined | "add" | "edit";

const TermManagerComponent: FC<TermManagerProps> = (
    props: TermManagerProps
) => {
    const [terms, setTerms] = useState<TermModel[]>([]);
    const [editMode, setEditMode] = useState<EditMode>(undefined);
    const [termCount, setTermCount] = useState<TermCountInterface>({activeCnt: 0, allCnt: 0, inActiveCnt: 0});
    const [listState, setListState] = useState<ListStates>(ListStates.Active);

    const [selectedTerm, setSelectedTerm] = useState<TermModel>(null);
    const [loading, setLoading] = useState(false);
    const termEditorRef = useRef<TermEditorRefProps>();

    const { match } = props;
    const {
        params: { regionId }
    } = match;

    const termManagerService = useService<ITermManagerService>(
        TYPES.ITermManagerService
    );
    const DateFormat = LanguageDateFormat[GLGlobal.intl.locale];

    useEffect(() => {
        loadTerms();
    }, [listState]);

    const loadTerms = () => {
        setLoading(true);
        let disabled;
        switch (listState) {
            case ListStates.Active:
                disabled = false;
                break;
            case ListStates.Inactive:
                disabled = true;
                break;
            default:
                disabled = undefined;
        }
        termManagerService.getTerms(regionId, disabled).then(response => {
            setTerms(response.data.map(t => ({
                ...t,
                // startDate: t.startDate && moment(t.startDate),
                // endDate: t.endDate && moment(t.endDate)
            })));
            setTermCount({
                allCnt: response.all,
                activeCnt: response.active,
                inActiveCnt: response.inactive,
            });
        }).finally(() => {
            setLoading(false);
        });
    };

    const editTerm = (term: TermModel) => {
        setEditMode("edit");
        openEditor(term);
    };

    const createTerm = () => {
        setEditMode("add");
        openEditor();
    };

    const openEditor = (term?: TermModel) => {
        setEditMode(term ? "edit" : "add");
        setSelectedTerm(term);
        termEditorRef.current.open(term);
    };

    const closeEditor = () => {
        setEditMode(undefined);
        termEditorRef.current.close();
    };

    const saveTerm = (raw: {
        id?: string,
        name: string;
        startDate?: string;
        endDate?: string;
        enforceDate: boolean;
    }) => {
        // not in add or edit mode
        if (!editMode) return;

        const data = {
            ...raw,
            regionId
        };

        // need termId for update existing term
        const termId = selectedTerm && selectedTerm.id;
        if (editMode === 'edit' && data.enforceDate) {
            // for updating term with enforceDate, need show warning message about number of affect classes
            termManagerService.countAffectClasses(termId).then(count => {
                Modal.confirm({
                    title: fmtMsg({ id: TermManagerLocale.ModalConfirmTitle }),
                    okText: fmtMsg({ id: TermManagerLocale.ModalButtonOkLabel }),
                    content: fmtMsg({ id: TermManagerLocale.ModalConfirmMessage }, { count }),
                    cancelText: fmtMsg({ id: TermManagerLocale.ModalButtonCancelLabel }),
                    onOk: () => {
                        createOrEdit(data);
                    }
                });
            })
        } else { // edit without enforce or create
            createOrEdit(data);
        }

        closeEditor();
    };

    const createOrEdit = (data) => {

        switch (editMode) {
            case 'add':
                termManagerService.createTerm(data).then(newTerm => {
                    setTermCount({
                        ...termCount,
                        allCnt: termCount.allCnt + 1,
                        activeCnt: !newTerm.disabled ? termCount.activeCnt + 1 : termCount.activeCnt,
                        inActiveCnt: newTerm.disabled ? termCount.inActiveCnt + 1 : termCount.inActiveCnt,
                    })
                    if (newTerm.disabled && listState === ListStates.Active || !newTerm.disabled && listState === ListStates.Inactive) {
                        return;
                    }
                    setTerms([...terms, {
                        ...newTerm,
                        // startDate: newTerm.startDate && moment(newTerm.startDate),
                        // endDate: newTerm.endDate && moment(newTerm.endDate)
                    }])
                }).catch(e => {
                    MessageHelper.ShowError(e);
                });
                break;

            case 'edit':
                termManagerService.updateTerm(data).then(() => {
                    if (data.disabled && listState === ListStates.Active || !data.disabled && listState === ListStates.Inactive) {
                        setTermCount({
                            ...termCount,
                            activeCnt: data.disabled ? termCount.activeCnt - 1 : termCount.activeCnt + 1,
                            inActiveCnt: data.disabled ? termCount.inActiveCnt + 1 : termCount.inActiveCnt - 1,
                        });
                        setTerms(terms.filter(t => t.id !== data.id));
                        return;
                    }
                    setTerms(terms.map(t => {
                        if (t.id === data.id) {
                            t = {
                                ...t, // original term's properties
                                ...data, // overwrite with modified properties
                                // startDate: data.startDate && moment(data.startDate),
                                // endDate: data.endDate && moment(data.endDate)
                            }
                        }
                        return t;
                    }))
                }).catch(e => {
                    MessageHelper.ShowError(e);
                });
                break;
            default:
                break;
        }
    }

    const deleteTerm = (term: TermModel) => {
        Modal.confirm({
            title: fmtMsg({ id: TermManagerLocale.ModalDeleteLabel }),
            content: fmtMsg(
                { id: TermManagerLocale.ModalConfirmDeleteMessage },
                { termName: term.name }
            ),
            okText: fmtMsg({ id: TermManagerLocale.ModalDeleteLabel }),
            onOk: () => {
                return termManagerService.deleteTerm(term.id).then(loadTerms).catch(e => {
                    MessageHelper.ShowError(e);
                });
            }
        });
    };

    const renderHead = () => {
        return (
            <React.Fragment>
                <SecondaryTitle title={TermManagerLocale.PageTitle} />
                <div className="term-manager__head">
                    <ListStatesRadios
                        {...termCount}
                        value={listState}
                        onChange={setListState}
                    />
                    <Actions noHeight>
                        <Action
                            textLocaleId={TermManagerLocale.AddLinkText}
                            onClick={() => {
                                createTerm();
                            }}
                            materialIcon="add"
                            action={GSAdminAction.CreateSchool}
                        />
                    </Actions>
                </div>
            </React.Fragment>
        );
    };

    const renderContent = () => {
        return (
            <div className="term-manager">
                <Grid itemsSource={terms} loading={loading}>
                    <Column
                        binding="name"
                        header={fmtMsg({
                            id: TermManagerLocale.GridNameLabel
                        })}
                        render={(value, term: TermModel) => {
                            return (
                                <a
                                    className={"term-manager__link " + (term.disabled ? "term-manager__disabled" : "")}
                                    onClick={() => {
                                        editTerm(term);
                                    }}
                                >
                                    {value}
                                </a>
                            );
                        }}
                    />
                    <Column
                        binding="startDate"
                        header={fmtMsg({
                            id: TermManagerLocale.GridStartDateLabel
                        })}
                        render={(value, term: TermModel) => {
                            if (value === 0) {
                                return value;
                            }
                            return (
                                <span>
                                    {value &&
                                        moment(value).format(DateFormat)}
                                </span>
                            );
                        }}
                    />
                    <Column
                        binding="endDate"
                        header={fmtMsg({
                            id: TermManagerLocale.GridEndDateLabel
                        })}
                        render={(value, term: TermModel) => {
                            return (
                                <span>
                                    {value &&
                                        moment(value).format(DateFormat)}
                                </span>
                            );
                        }}
                    />
                    <Column
                        binding="enforceDate"
                        header={fmtMsg({
                            id: TermManagerLocale.GridEnforceDateLabel
                        })}
                        render={(value, term: TermModel) => {
                            return <Checkbox checked={value} disabled={true} />;
                        }}
                    />
                    {
                        <Column
                            header={fmtMsg({
                                id: TermManagerLocale.GridRemoveLabel
                            })}
                            align="center"
                            render={(value, term: TermModel) => {
                                return (
                                    <a
                                        className="term-manager__remove"
                                        onClick={() => {
                                            deleteTerm(term);
                                        }}
                                    >
                                        <GLIcon type="icon-delete" />
                                    </a>
                                );
                            }}
                        />
                    }
                </Grid>
            </div>
        );
    };

    const renderTermEditor = () => {
        return (
            <TermEditor
                getRef={termEditorRef}
                reloadTerms={loadTerms}
                saveTerm={saveTerm}
                term={selectedTerm}
            />
        );
    };

    return (
        <Container>
            {renderHead()}
            {renderContent()}
            {renderTermEditor()}
        </Container>
    );
};

export const TermManagerPage = withRouter(
    connect((state: StateType) => ({}), {})(TermManagerComponent)
);
