import { GSAdminLocale, TermManagerLocale } from "@app/locales/localeid";
import { FormItem } from "@app/page/cims/component/cims-consts";
import {
    CreateOrUpdateTermRequestModel,
    TermModel
} from "@app/service/term-manager/model";
import { fmtMsg } from "@app/util";
import {
    Checkbox,
    DatePicker,
    Form,
    Input,
    Modal,
    List,
    Button,
    Row,
    Col,
} from "antd-min";
import { GLForm, GLFormComponentProps, GLGlobal, LanguageDateFormat } from "gl-commonui";
import moment, { Moment } from "moment";
import React, { useEffect, useRef, useState } from "react";
import { FC } from "react";

export interface TermEditorRefProps {
    open: (term?: TermModel) => void;
    close: () => void;
}

export interface TermEditorProps extends GLFormComponentProps {
    getRef?: React.MutableRefObject<TermEditorRefProps>;
    reloadTerms: () => void;
    saveTerm: (raw: any) => void;
    term?: TermModel;
}

enum EditorMode {
    Create,
    Edit
}

const TermEditorComponent: FC<TermEditorProps> = props => {
    const { form, getRef, saveTerm, reloadTerms } = props;
    const {
        getFieldDecorator,
        setFieldsValue,
        resetFields,
        getFieldsValue,
        getFieldValue,
        setFields
    } = form;
    // const editorMode = term ? EditorMode.Edit : EditorMode.Create;
    const editorMode = useRef<EditorMode>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const [term, setTerm] = useState<TermModel>(null);

    const DateFormat = LanguageDateFormat[GLGlobal.intl.locale];
    const [startDate, setStartDate] = useState<string>(null);
    const [endDate, setEndDate] = useState<string>(null);
    const [isDisableEnforceDate, setIsDisableEnforceDate] = useState(false);

    useEffect(() => {
        const shouldDisableEnforceDate = startDate === null || endDate === null;
        if (shouldDisableEnforceDate) {
            setFieldsValue({
                ...getFieldsValue(),
                enforceDate: false
            });
        }
        setIsDisableEnforceDate(shouldDisableEnforceDate);
    }, [startDate, endDate, resetFields]);

    const submitHandler = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            form.validateFields(
                (err, values: CreateOrUpdateTermRequestModel) => {
                    if (err) {
                        reject();
                    } else {
                        saveTerm({
                            ...term, // original values
                            ...values, // override with modified values
                            startDate,
                            endDate
                        });
                    }
                }
            );
        });
    };

    const onOk = () => {
        // setLoading(true);
        submitHandler();
    };

    const open = (term?: TermModel) => {
        setTerm(term);
        if (term) { // edit term, set these values for purpose update Checkbox enforDate
            setStartDate(term.startDate && moment(term.startDate).format(DateFormat));
            setEndDate(term.endDate && moment(term.endDate).format(DateFormat));

            setFieldsValue({
                name: term.name,
                startDate: term.startDate && moment(term.startDate),
                endDate: term.endDate && moment(term.endDate),
                enforceDate: term.enforceDate,
                disabled: term.disabled
            });
        }
        editorMode.current = term ? EditorMode.Edit : EditorMode.Create;
        setVisible(true);
    };

    const resetModal = () => {
        // update state of Checkbox enforceDate
        setStartDate(null);
        setEndDate(null);

        // reset all field values
        resetFields();
    }

    const close = () => {
        setVisible(false);
    };

    useEffect(() => {
        passRef();
    }, []);

    const passRef = () => {
        if (getRef) {
            getRef.current = {
                open,
                close
            };
        }
    };

    return (
        <Modal
            centered={true}
            title={
                <div
                    style={{
                        textAlign: "center"
                    }}
                >
                    {fmtMsg(GSAdminLocale.RegionTermManager)}
                </div>
            }
            visible={visible}
            okText={fmtMsg(TermManagerLocale.ModalSaveLabel)}
            cancelText={fmtMsg(TermManagerLocale.ModalCancelLabel)}
            onOk={onOk}
            onCancel={close}
            afterClose={resetModal}
        >
            <GLForm
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
            >
                <FormItem
                    label={fmtMsg({ id: TermManagerLocale.ModalNameLabel })}
                >
                    {getFieldDecorator("name", {
                        rules: [
                            {
                                required: true
                            }
                        ]
                    })(<Input autoComplete="term-name" />)}
                </FormItem>
                <FormItem
                    label={fmtMsg({
                        id: TermManagerLocale.ModalStartDateLabel
                    })}
                >
                    {getFieldDecorator("startDate", {
                        rules: [
                            {
                                required: false
                            }
                        ]
                    })(
                        <DatePicker
                            onChange={(date?: Moment, dateString?: string) => {
                                setStartDate(dateString);
                            }}
                        />
                    )}
                </FormItem>
                <FormItem
                    label={fmtMsg({ id: TermManagerLocale.ModalEndDateLabel })}
                >
                    {getFieldDecorator("endDate", {
                        rules: [
                            {
                                required: false
                            }
                        ]
                    })(
                        <DatePicker
                            onChange={(date?: Moment, dateString?: string) => {
                                setEndDate(dateString);
                            }}
                        />
                    )}
                </FormItem>
                <Row>
                    <Col span={12}>
                        <FormItem
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                            label={fmtMsg({ id: TermManagerLocale.ModalEnforceDateLabel })}
                        >
                            {getFieldDecorator("enforceDate", {
                                rules: [
                                    {
                                        required: false
                                    }
                                ],
                                initialValue: false,
                                valuePropName: 'checked',
                            })(<Checkbox disabled={isDisableEnforceDate} />)}
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            labelCol={{ span: 9 }}
                            wrapperCol={{ span: 15 }}
                            label={fmtMsg({ id: TermManagerLocale.ModalDisabledLabel })}
                        >
                            {getFieldDecorator("disabled", {
                                rules: [
                                    {
                                        required: false
                                    }
                                ],
                                initialValue: false,
                                valuePropName: 'checked',
                            })(<Checkbox />)}
                        </FormItem>
                    </Col>
                </Row>
            </GLForm>
        </Modal>
    );
};

export const TermEditor = GLForm.create({ name: "term-editor" })(
    TermEditorComponent
);
